let body                   = document.querySelector('body'),
    instructionsMenu       = document.querySelector('.js-instructions-navbar'),
    instructionsMenuAnchor = document.querySelectorAll('.js-instructions-navbar a'),
    menuToggle             = document.querySelector('.js-instructions-navbar__label'),
    menu                   = document.querySelector('.js-instructions-navbar__input');

let enterKeyCode = 73;
let escKeyCode = 27;

instructionsMenuAnchor.forEach(x => x.tabIndex = -1);

if (menuToggle) {
  menuToggle.addEventListener('keydown', function(event) {
    if(event.keyCode == enterKeyCode) {
      let menuOpen = menu.checked;

      if (menuOpen) {
        menu.checked = false;
      } else {
          menu.checked = true;
      }
    }

    if(event.keyCode == escKeyCode) {
      let menuOpen = menu.checked;

      if (menuOpen) {
        menu.checked = false;
      }
    }
  });

  function bodyToggle() {
    let menuOpen = menu.checked;

    if (menuOpen) {
      body.classList.remove('navbar-open');
      instructionsMenuAnchor.forEach(x => x.tabIndex = -1);
    } else {
      body.classList.add('navbar-open');
      instructionsMenuAnchor.forEach(x => x.tabIndex = 0);
    }
  }

  function trapFocus(el) {
    let focusableEls = el.querySelectorAll('a[href]:not([disabled]), label:not([disabled])');
    let firstFocusableEl = focusableEls[0];
    let lastFocusableEl = focusableEls[focusableEls.length - 1];
    let KEYCODE_TAB = 9;

    el.addEventListener('keydown', function(e) {
      let isTabPressed = (e.key === 'Tab' || e.keyCode === KEYCODE_TAB);

      if (!isTabPressed) {
        return;
      }

      if ( e.shiftKey ) /* shift + tab */ {
        if (document.activeElement === firstFocusableEl) {
          lastFocusableEl.focus();
            e.preventDefault();
          }
        } else /* tab */ {
        if (document.activeElement === lastFocusableEl) {
          firstFocusableEl.focus();
            e.preventDefault();
          }
        }
    });
  }

  menuToggle.addEventListener('click', function() {
    bodyToggle();
    trapFocus(instructionsMenu);
  });

}
